// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const smartContractObj = store.getState().blockchain.smartContract;
      const validatorSmartContractObj = store.getState().blockchain.validatorSmartContract;
      const phaseId = Number(process.env.REACT_APP_PHASE_ID);
      const account = store.getState().blockchain.account;
      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      // const mintedAmount = Number(await smartContractObj.methods.mintedAmount(phaseId).call({ from: account }));
      const phaseData = await smartContractObj.methods.phaseData(phaseId).call();
      const onSale = phaseData.onSale;
      const cost = await validatorSmartContractObj.methods.getSbtPrice().call();
      const maxAmount = Number(await validatorSmartContractObj.methods.maxAmount().call());
      const userMintedAmount = Number(await validatorSmartContractObj.methods.getUserMintedAmount(account).call());

      dispatch(
        fetchDataSuccess({
          onSale,
          cost,
          maxAmount,
          userMintedAmount,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
