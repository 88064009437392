const initialState = {
  loading: false,
  onSale: false,
  cost: 0,
  maxAmount: 0,
  remainAmount: 0,
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...state,
        loading: false,
        onSale: action.payload.onSale,
        userMintedAmount: action.payload.userMintedAmount,
        cost: action.payload.cost,
        maxAmount: action.payload.maxAmount,
        remainAmount: action.payload.maxAmount - action.payload.userMintedAmount,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
