import React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    numberInput: {
        width: '60px',
        textAlign: 'center', // テキストを中央寄せ
        backgroundColor: 'white', // 背景色を白に
        borderRadius: '4px', // 角丸の追加
        '& input': {
            textAlign: 'center', // 入力された数値を中央寄せ
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            // スピンコントロールの非表示
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& input[type=number]': {
            MozAppearance: 'textfield', // Firefoxでのスピンコントロール非表示
        },
    },
});

function NumberInput({ number, setNumber, max }) {
    const classes = useStyles();

    const handleChange = (event) => {
        const value = Math.max(0, Math.min(max, Number(event.target.value)));
        setNumber(value);
    };

    const increment = () => {
        setNumber((prevNumber) => {
            const newValue = parseInt(prevNumber, 10) + 1;
            return newValue <= max ? newValue : prevNumber;
        });
    };

    const decrement = () => {
        setNumber((prevNumber) => Math.max(0, parseInt(prevNumber, 10) - 1));
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={decrement} color="primary" disabled={number <= 0}>
                <RemoveCircleOutlineIcon />
            </IconButton>
            <Input
                type="number"
                value={number}
                onChange={handleChange}
                inputProps={{ min: 0, max: max }}
                className={classes.numberInput}
            />
            <IconButton onClick={increment} color="primary" disabled={number >= max}>
                <AddCircleOutlineIcon />
            </IconButton>
        </Box>
    );
}

export default NumberInput;
