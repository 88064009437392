import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import NumberInput from "./NumberInput";
import {getConfigPath, isDev} from "./util";

const useStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%', // ここで幅を設定
    // 他のスタイルもここに追加可能
  },
  // 親要素のスタイル（必要に応じて）
  container: {
    maxWidth: '600px', // 最大幅を設定
    margin: '0 auto', // 中央揃え
  },
}));

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :disabled {
    background-color: #808080;
    cursor: default;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 250px;
  @media (min-width: 767px) {
    width: 250px;
  }
`;

export const StyledImg = styled.img`
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`1人3点まで`);
  const [number, setNumber] = useState(0);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    VALIDATOR_CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost * number);
    console.log("Cost: ", totalCostWei);
    setFeedback(`ミント中...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(process.env.REACT_APP_PHASE_ID, number, 0, [])
      .send({
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("失敗");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `ミント成功！`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsByCreditCard = () => {
    const quantity = 'quantity=' + number;
    const recipientAddress = 'recipientAddress=' + blockchain.account;
    const baseUrl = isDev() ?
        'https://testnets.paypiement.xyz/projects/202c108d-ff61-494c-8563-68079b43d95c?' :
        'https://paypiement.xyz/projects/e70b889d-dd91-439f-a60e-2e307fb8e411?';

    window.open(baseUrl + quantity + '&' + recipientAddress, '_blank');
  }

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch(getConfigPath(), {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const isButtonDisabled = claimingNft || !data.onSale || data.userMintedAmount >= data.maxAmount || number === 0 || number > data.remainAmount;

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo2.jpg"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg />
          </s.Container>
          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              CNC SBT Collection
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK + CONFIG.CONTRACT_ADDRESS}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}
                  {CONFIG.NETWORK.SYMBOL}.
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {CONFIG.NETWORK.NAME} Networkのウォレットを接続してください
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {data.userMintedAmount > data.maxAmount ? "既にミント済みです": !data.onSale ? "未セール中" : ""}
                      </s.TextDescription>
                    <s.SpacerSmall />
                    <NumberInput number={number} setNumber={setNumber} max={data.remainAmount} />
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={isButtonDisabled}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "待機" : data.userMintedAmount >= data.maxAmount ? "購入済" : "ETH購入" }
                      </StyledButton>
                    </s.Container>
                    <s.SpacerMedium />
                    <StyledButton
                        disabled={isButtonDisabled}
                        onClick={claimNFTsByCreditCard}
                    >
                      {claimingNft ? "待機" : data.userMintedAmount >= data.maxAmount ? "購入済" : "クレカ購入" }
                    </StyledButton>
                    <s.SpacerMedium />
                    <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                    >
                      OpenSeaへのリンクは<a href={CONFIG.MARKETPLACE_LINK} target="_blank">こちら</a>
                    </s.TextDescription>
                  </>
                )}
              </>
            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
                style={{ transform: "scaleX(-1)" }}
            />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            1人3点までミント可能です。<br />
            Up to 3 items per person can be minted.<br />
            <br />
            正しいネットワークに接続されているか確認してください ({CONFIG.NETWORK.NAME} Mainnet)。<br />
            Please confirm you connect to Ethereum Mainnet.<br />
            <br />
            ガス代が低すぎると失敗することがあります。適切なガス代を設定してください。<br />
            Please set appropriate Gas fee for mint.
          </s.TextDescription>
          <s.SpacerSmall />
        </s.Container>
        <div className={classes.container}>
          <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography>3Dセキュアについて</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Piementでは3Dセキュア2.0というカード会社の追加認証を必須にしておりまして、 利用者が自身で認証登録されていないと基本的に決済がすべて弾かれる形になっております。<br />
                （NFTという特性上、不正利用されるケースが多くあり、防護策として3Dセキュア2.0を必須で適用しております）<br />
                <br />
                以下、大手の3Dセキュア認証説明ページになります。<br />
                - <a href={"https://www.rakuten-card.co.jp/security/3d-secure/"} target="_blank">楽天カード</a><br />
                - <a href={"https://www.smbc-card.com/mem/service/sec/secure01.jsp"} target="_blank">三井住友</a><br />
                - <a href={"https://dcard.docomo.ne.jp/st/member_settings/security/authshopping/index.html"} target="_blank">dカード</a><br />
                - <a href={"https://www.saisoncard.co.jp/service/3d-secure/"} target="_blank">セゾン</a><br />
                - <a href={"https://www.jcb.co.jp/service/safe-secure/activity/jsecure/index.html"} target="_blank">JCB</a><br />
                - <a href={"https://www.americanexpress.com/jp/security/safekey.html"} target="_blank">AMEX</a><br />
                <br />
                決済が失敗するお客様においては、まず第一に3Dセキュア認証の設定をご確認いただければと思います。
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography>決済失敗が頻発する場合</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                楽天カードなど、初回決済時にカード会社側の判断で「保留・却下」扱いとなるケースが多く見受けられます。<br />
                3Dセキュア設定が済んでいる状態で連続して決済が通らない場合、まずカード会社に問い合わせいただいて状況を確認し、保留・却下されていた場合は<br />
                <br />
                - 自身の決済であること<br />
                - これから[Piement(パイメント)]にて同様の決済を行うこと<br />
                をお伝えいただけると幸いです。<br />
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </s.Container>
    </s.Screen>
  );
}

export default App;
